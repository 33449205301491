import { FunctionComponent } from "react";
import { SideMenuProps } from "./SideMenuTypes";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";

export const SideMenu: FunctionComponent<SideMenuProps> = ({
  links,
  menuTitle,
}) => {
  return (
    <div className={styles.linkContainer}>
      <div className={styles.links}>
        <div className={styles.link}>{menuTitle}</div>
        {links?.map((link) => {
          return (
            <NavLink
              key={link.url}
              to={link.url}
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : styles.link
              }
            >
              {link.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
