import { FunctionComponent } from "react";
import { Hero, Service } from "components";
import { services } from "utils/data";
import styles from "./index.module.scss";
import optionsImg from "./options.svg";

export const Services: FunctionComponent = () => {
  return (
    <>
      <Hero
        title="Our Services"
        subtitle="Specialized in commercial IT, as well as solutions for local and federal government infrastructures."
        img={optionsImg}
        imgAlt="guy thinking about options"
      />
      <div className={styles.serviceContainer}>
        {services.map((service) => {
          return <Service service={service} key={service.name} />;
        })}
      </div>
    </>
  );
};
