import { FunctionComponent } from "react";
import { ButtonProps } from "./ButtonTypes";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export const Button: FunctionComponent<ButtonProps> = ({ to, text }) => {
  return (
    <div className={styles.buttonContainer}>
      <Link to={to} className={styles.mainButton}>
        {text}
      </Link>
    </div>
  );
};
