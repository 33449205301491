import { useState, useEffect, FunctionComponent } from "react";
import { useParams } from "react-router";
import { Button, Hero, SideMenu } from "components";
import { Service } from "./ServiceTypes";
import { services } from "utils/data.js";
import styles from "./index.module.scss";
import optionsImg from "./options.svg";

const renderService = (serviceContent: Service) => {
  const service = serviceContent.long_desc;
  return (
    <>
      <h2 className={styles.headingTwo}>{service?.heading}</h2>
      {service?.description?.map((desc: string) => {
        return (
          <p className={styles.text} key={desc}>
            {desc}
          </p>
        );
      })}
      {service?.content?.map((content: any) => {
        return (
          <div key={content.header}>
            <p className={`${styles.text} ${styles.textStrong}`}>
              {content?.header}
            </p>
            <ul>
              {content?.bullets?.map((bullet: string) => {
                return (
                  <li className={styles.bullet} key={bullet}>
                    {bullet}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};

export const ServiceDetails: FunctionComponent = () => {
  const [service, setService] = useState<Service>();

  const params = useParams();

  useEffect(() => {
    setService(services?.filter((s) => s.slug === params.id)[0]);
  }, [params]);

  return (
    <div className={styles.detailsContainer}>
      <Hero
        title={service?.name || ""}
        subtitle={service?.description || ""}
        img={optionsImg}
        imgAlt="guy thinking about options"
      />
      <div className={styles.innerContainer}>
        <SideMenu
          menuTitle="Services"
          links={services.map((s) => {
            return {
              name: s.name,
              url: `/services/details/${s.slug}`,
            };
          })}
        />
        <div className={styles.serviceBox}>
          {service && renderService(service)}
          <Button to="/Contact" text="Request a Free Consultation" />
        </div>
      </div>
    </div>
  );
};
