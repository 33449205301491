import { Routes, Route } from "react-router-dom";
import {
  About,
  Careers,
  Contact,
  Home,
  ServiceDetails,
  Services,
} from "containers";
import { Error, Footer, Header, PressRelease } from "components";
import styles from "./index.module.scss";

export const App = () => {
  return (
    <div className={styles.main}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<Careers />} />
        <Route path="/services/details/:id" element={<ServiceDetails />} />
        <Route path="/press-release/:id" element={<PressRelease />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </div>
  );
};
