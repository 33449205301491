import { FunctionComponent, useEffect } from "react";
import { Hero } from "components";
import styles from "./index.module.scss";

export const Careers: FunctionComponent = () => {
  const getOpenJobs = async () => {
    return await fetch(
      "https://www.ziprecruiter.com/jobs-widget-content/v1/b3fd66ce?show_posted_days=0"
    );
  };

  useEffect(() => {
    getOpenJobs()
      .then((res) => res.text())
      .then((data) => {
        if (!document.getElementById("jobs_widget_container")) {
          var parser = new DOMParser();
          var doc = parser.parseFromString(data, "text/html");
          document
            .getElementById("zipRecruiterWidget")
            ?.appendChild(doc.documentElement);
        }
      });
  }, []);

  return (
    <>
      <Hero
        title="Careers"
        subtitle="Find your next role with us! Review the open jobs below or send your resume to careers@bbmeyers.com"
      />
      <div className={styles.careersContainer}>
        <div className={styles.careerBox}>
          <div
            id="zipRecruiterWidget"
            className={styles.zipRecruiterWidget}
          ></div>
          <a
            className={styles.link}
            href="https://www.ziprecruiter.com/c/Bainbridge-Meyers-LLC/Jobs?hiring_company=b3fd66ce"
            id="jobs_widget_company_link"
            target="_blank"
            rel="noreferrer"
          >
            Find jobs at Bainbridge Meyers LLC
          </a>
        </div>
      </div>
    </>
  );
};
