import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import logo from "./logo-min.png";

export const Header: FunctionComponent = () => {
  const [menuVisbility, setMenuVisbility] = useState(false);
  const toggleMenu = (visibility: boolean) => {
    setMenuVisbility(visibility);
  };

  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <NavLink to="/Home">
          <img
            className={styles.logo}
            src={logo}
            alt="Bainbridge Meyers logo"
          />
        </NavLink>
      </div>

      <div
        className={
          menuVisbility ? styles.mobileLinkContainer : styles.linkContainer
        }
      >
        <div className={menuVisbility ? styles.mobileNav : styles.regularNav}>
          <NavLink
            onClick={() => toggleMenu(false)}
            to="/Home"
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.active}` : styles.link
            }
          >
            Home
          </NavLink>
          <NavLink
            onClick={() => toggleMenu(false)}
            to="/About"
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.active}` : styles.link
            }
          >
            About
          </NavLink>
          <NavLink
            onClick={() => toggleMenu(false)}
            to="/Services"
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.active}` : styles.link
            }
          >
            Services
          </NavLink>
          <NavLink
            onClick={() => toggleMenu(false)}
            to="/Careers"
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.active}` : styles.link
            }
          >
            Careers
          </NavLink>
          <NavLink
            onClick={() => toggleMenu(false)}
            to="/Contact"
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.active}` : styles.link
            }
          >
            Contact
          </NavLink>
        </div>
      </div>
      <div
        className={styles.menuIcon}
        onClick={() => toggleMenu(!menuVisbility)}
      >
        <i className="material-icons">menu</i>
      </div>
    </div>
  );
};
