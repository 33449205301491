import { FunctionComponent, useEffect, useState } from "react";
import { Hero } from "components";
import { PressReleaseType } from "./PressReleaseTypes";
import { useParams } from "react-router-dom";
import { pressReleases } from "../../utils/data.js";
import styles from "./index.module.scss";

const renderPressRelease = (pressRelease: PressReleaseType) => {
  return (
    <div>
      <p className={styles.preContent}>{pressRelease?.preContent}</p>
      <span className={styles.textStrong}>{pressRelease?.prInfo} </span>
      {pressRelease?.body?.map((content: any, i: number) => {
        return content instanceof Object ? (
          <div className={styles.quoteContainer} key={i}>
            <p className={styles.quoteText}>{content.quoteText}</p>
            <p className={styles.quoteByLine}>{content.quoteByline}</p>
          </div>
        ) : (
          <p className={styles.text} key={i}>
            {content}
          </p>
        );
      })}
    </div>
  );
};

export const PressRelease: FunctionComponent = () => {
  const [pressRelease, setPressRelease] = useState<PressReleaseType>();

  const params = useParams();

  useEffect(() => {
    setPressRelease(pressReleases?.filter((pr) => pr.slug === params.id)[0]);
  }, [params]);

  return (
    <>
      <Hero title="Press Release" subtitle={pressRelease?.preview || ""} />
      <div className={styles.container}>
        <h2 className={styles.heading}>{pressRelease?.heading}</h2>
        <span className={styles.date}> {pressRelease?.date}</span>
        <span className={styles.headingAccentLg}></span>
        {pressRelease && renderPressRelease(pressRelease)}
      </div>
    </>
  );
};
