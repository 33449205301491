import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Hero } from "components";
import { PressReleaseType } from "components/PressRelease/PressReleaseTypes";
import { pressReleases } from "utils/data";
import styles from "./index.module.scss";
import about from "./about.svg";

export const About: FunctionComponent = () => {
  return (
    <>
      <Hero
        title="About Us"
        subtitle="We're Bainbridge Meyers, your trusted source for all things IT"
        img={about}
        imgAlt="buisness deal"
      />
      <div className={styles.container}>
        <div className={styles.aboutRow}>
          <div className={`${styles.innerContainer} ${styles.half}`}>
            <h3 className={styles.headingThree}>Our Mission</h3>
            <span className={styles.headingAccent}></span>
            <p>
              Bainbridge Meyers, LLC is committed to providing custom-tailored
              IT solutions that fit your organization’s unique needs. Our
              mission is to deliver industry-leading IT management solutions
              that offer government and commercial clients the ability to focus
              on their business goals.
            </p>
            <p>
              Our best-in-class solutions meet and exceed industry best
              practices, backed by more than 25 years of IT expertise.
            </p>
          </div>
          <div className={`${styles.innerContainer} ${styles.half}`}>
            <h3 className={styles.headingThree}>Our Promise</h3>
            <span className={styles.headingAccent}></span>
            <p>
              We are invested in our clients’ success, which is why we stress
              the importance of listening. We want to understand your unique IT
              challenges to offer solutions aligned with your business timelines
              and requirements. Through careful planning and research, we
              promise we’ll work with you to determine the right IT solution for
              your organization.
            </p>
          </div>
        </div>
        <div className={`${styles.innerContainer} ${styles.full}`}>
          <h3 className={styles.headingThree}>Company News</h3>
          <span className={styles.headingAccent}></span>
          <div className={styles.companyNews}>
            {pressReleases?.map((pr: PressReleaseType, i: number) => {
              return (
                <div className={styles.pressRelease} key={i}>
                  <h4 className={styles.headingFour}>{pr.heading}</h4>

                  <span className={styles.date}>{pr.date}</span>
                  <span className={styles.shortDesc}>{pr.preview}</span>
                  <Link
                    className={styles.link}
                    to={{ pathname: `/press-release/${pr.slug}` }}
                  >
                    Read More...
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
