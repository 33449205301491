export const services = [
  {
    name: "Cloud Solutions",
    slug: "cloud-solutions",
    description:
      "Is your business ready to move to the cloud? Our implementation teams will assist in your transition by removing the guesswork and anxiety from the migration process - getting you up and running faster and more efficiently, without interruption.",
    long_desc: {
      heading: "Cloud Solutions",
      description: [
        "Ready to make the move to the cloud but are unsure of where to start? Our team of cloud experts can navigate the process and work on behalf of, or augmenting your IT team. We will work with you to make the right decisions and develop a migration strategy to make your transition to the cloud as seamless and 'pain free' as possible, minimizing downtime.",
        "Our team of cloud experts will take the guesswork out of:",
      ],
      content: [
        {
          header: "Cloud Migration",
          bullets: [
            "Whether you're moving to the cloud or from the cloud we can help. Our team of experts will assess your current footprint to develop and design the right solution for you today - and to plan for future growth.",
          ],
        },
        {
          header: "Updating Your Cloud Strategy",
          bullets: [
            "Already in the cloud but don't know how to move to the next level? Our cloud consultants will review your business needs and determine what the process is to get you to that next level as seamlessly as possible.",
          ],
        },
        {
          header: "Manage/Audit Cloud Security",
          bullets: [
            "Security is paramount to your online business - our team of cloud consultants will review your cloud profile and determine the necessary changes to be made.",
          ],
        },
        {
          header: "Manage Your Cloud Presence",
          bullets: [
            "If you're up and running and just need some experts to augment your staff, we're here to help. From systems/OS support to application support, we’ve got you covered.",
          ],
        },
      ],
    },
  },
  {
    name: "Managed IT Services",
    slug: "managed-it-services",
    description:
      "Our team of professionals know how to identify gaps in your organization's IT teams and resources. From staff augmentation to full IT project management, our solutions help your organization scale to meet and exceed fluid industry demands.",
    long_desc: {
      heading: "Managed IT Services",
      description: [
        "We offer reliable management of all your IT needs to ensure you're always up and running & on top of industry best practices. Whether we’re providing your IT staff or simply augmenting it, we can meet all of your IT needs.",
      ],
      content: [
        {
          header: "Data Center Operations",
          bullets: [
            "Fully supported datacenter operations from the complete lifecycle of equipment to support equipment (racks, cabling, inventory…)",
            "Fully managed server Operating systems and applications, including hardware.",
            "Maintenance renewals and support contracts.",
            "Tech refreshes and upgrades.",
            "Working with vendor support.",
          ],
        },
        {
          header: "Application Support",
          bullets: [
            "Providing support to your applications is as mission critical as your OS/hardware. We provide application support to include troubleshooting, installation, and configuration, as well as recommendations for most COTS software so keep you up and running.",
          ],
        },
        {
          header: "Onsite & Remote Support",
          bullets: [
            "Providing support to meet your needs, our staff can be onsite or, through secure VPN, provide remote support 24/7.",
            "Day to day IT operations",
            "Upgrades - OS and applications",
            "Onsite physical operations. (racking, cabling, server implementation)",
          ],
        },
        {
          header: "Network Management",
          bullets: [
            "Our top-notch network professionals have what it takes to tackle the toughest networks.",
            "Supporting the latest in network technology hardware, monitoring solutions, and network analysis software.",
          ],
        },
        {
          header: "Security Compliance",
          bullets: [
            "‘Audit Season’? Our professionals will work with your auditors to identify any compliance concerns and provide a complete solution, including implementation, to bring your IT into compliance.",
          ],
        },
      ],
    },
  },
  {
    name: "Support Services",
    slug: "support-services",
    description:
      "We will work with you to offer the support you need to address the IT-related challenges within your organization and provide long-term solutions built for you.",
    long_desc: {
      heading: "Support Consulting",
      description: [
        "Not sure where to start with your IT solutions? Our experts are here to help. We know every business is different. That's why our dedicated staff listen to your needs and develop the best solution for you. As a Red Hat business partner and an IBM registered partner, we can not only navigate the IT side but also have access to the in-depth information needed to procure your solution.",
      ],
      content: [
        {
          header: "",
          bullets: [
            "Thorough IT Consultation",
            "Onsite & Remote Training",
            "Device Agnostic Support",
            "Strategy & Roadmap Planning",
          ],
        },
      ],
    },
  },
  {
    name: "System Design and Development",
    slug: "system-design-and-development",
    description:
      "Need help designing and developing systems? Let our professionals build your application from beginning to end. We design and develop the architecture, including  interfaces, components, modules, and data.",
    long_desc: {
      heading: "System Design and Development",
      description: [
        "Need help designing and developing systems? Let our professionals build your application from beginning to end. We design and develop the architecture, including interfaces, components, modules, and data.",
      ],
      content: [
        {
          header: "Systems Design",
          bullets: [
            "We will take a systematic approach to design coherent and optimized systems. From software design/installation to hardware/OS choices, we can provide the right solution for your business/IT needs. Whether ‘top-down’ or ‘bottom-up’, we will analyze your needs to account for a wide range of possibilities. This allows a system to support your existing needs and expansion/growth as your IT and business needs change.",
            "Through our agile approach we maintain flexibility in the software design process leading to exact solutions that meet needs across your business units/departments.",
          ],
        },
        {
          header: "Development",
          bullets: [
            "Our developers will assess your drivers and needs to develop your custom software or application (cloud or on-premise). We follow an agile approach which allows better definition of your requirements and evolve as we collaborate with your teams and work toward your solution.",
          ],
        },
      ],
    },
  },
];

export const pressReleases = [
  {
    heading:
      "Bainbridge Meyers, LLC selected to assist Moser Consulting on their Railroad Retirement board contract",
    slug: "railroad-retirement-contract",
    preview:
      "Bainbridge Meyers, LLC (BBM)  has just successfully completed a contract with Moser Consulting assisting the Railroad Retirement Board (RRB) with on-boarding users to new mobile devices and helpdesk support for those devices.",
    date: "August 15, 2020",
    prInfo: "Forest Hill, MD 08/15/2020;",
    body: [
      "Bainbridge Meyers, LLC (BBM)  has just successfully completed a contract with Moser Consulting assisting the Railroad Retirement Board (RRB) with on-boarding users to new mobile devices and helpdesk support for those devices. BBM team members spent many hours working alongside Moser Consulting team members on this project which was a huge success.",
      "The BBM team worked with the end users to ensure their devices were setup and configured properly as well as provided troubleshooting as needed. The completion of the several week long project was a success. Our thanks to Moser consulting for choosing BBM to assist on this project.",
    ],
  },
  {
    heading:
      "Bainbridge Meyers, LLC Receives Veteran Institute for Procurement Start Certification",
    slug: "vip-start-certification",
    preview:
      "On October 24, 2019, Bainbridge Meyers, LLC was one of 48 businesses from 18 states plus DC and PR to graduate from the Veteran Institute for Procurement (VIP) START Program.",
    date: "November 2, 2019",
    preContent:
      "From contracting to teaming and everything in between, this program offers solid foundational knowledge that will help our company succeed in the Federal Marketplace.",
    prInfo: "Forest Hill, MD  10/29/2019;",
    body: [
      "Bainbridge Meyers, LLC recently completed the Veteran Institute Procurement (VIP) START Program, a comprehensive training and certification program designed for veteran owned businesses that want to enter or expand their business growth into the federal marketplace through best business practices.",
      "On October 24, 2019, Bainbridge Meyers, LLC was one of 48 businesses from 18 states plus DC and PR to graduate from the Veteran Institute for Procurement (VIP) START Program.",
      {
        quoteText:
          "“The program is an amazing resource as well as a mastermind for Veteran Owned Small Business (VOSB) and Service Disabled Veteran Owned Small Business (SDVOSB) owners to learn from a talented pool of instructors as well as professionals on owning and operating your own business in the Federal marketplace. A deep heartfelt thanks to all those who support and run this outstanding program. And a special thanks to Barbara Ashe for 10 YEARS of running and supporting this program!  I look forward to many more!”",
        quoteByline:
          "- Patrick Christopher President/CEO Bainbridge Meyers, LLC",
      },
      "The first program of its kind in the nation, VIP START is conducted by professional subject-matter specialists in the essentials necessary to win government contracts: law, accounting, insurance, human resources, marketing and proposals. The curriculum is designed to help companies accelerate the rate at becoming procurement ready. The program also provides participants with access to Federal and prime contracting executives along with a national network of veteran-owned small businesses that they can team with on opportunities or serve as mentors.",
      '"We are honored to give back to the men and women who served our country by providing them with the tools necessary to succeed as government contractors." said Barbara Ashe, President of the Montgomery County Chamber Community Foundation. “We hope this training fosters their success as businesses and employers.”',
      "VIP START is a three-day, 27-hour comprehensive certification program. Participants must be a C-level executive in a Veteran-owned small business operating for at least 1 year with one year of revenue generation; must be a W-2 in their company, or full time employee, and spend 100% of their time in their business; and a small business. With volunteer instructors and at no cost to participants, VIP START is fully funded by the Montgomery County Chamber Community Foundation, partners, and VIP sponsors. Bainbridge Meyers, LLC  is a IT managed services company operated out of Forest Hill, Maryland.",
    ],
    prDownloadLink: {
      url: "../vip-start-press-release.pdf",
      text: "Download the press release",
    },
  },
  {
    heading:
      "Bainbridge Meyers, LLC All Small Mentor Protégé Program with Moser Consulting",
    slug: "mentor-protege-program",
    preview:
      "The Small Business Administration has approved the All Small Mentor Protégé Program (ASMPP) agreement between Moser Consulting (Mentor) and Bainbridge Meyers, LLC (protégé).",
    date: "Oct 4, 2019",
    prInfo: "Forest Hill, MD 10/4/2019;",
    body: [
      "The Small Business Administration has approved the All Small Mentor Protégé Program (ASMPP) agreement between Moser Consulting (Mentor) and Bainbridge Meyers, LLC (protégé).",
      "This agreement is for 3 years and its purpose is to enhance the development of the protégé and encourage approved mentors to provide various forms of assistance to eligible ASMPP participants to ensure contracting opportunity, experience and overall financial viability.",
      {
        quoteText:
          "“BBM looks forward to being part of the program with a superior mentor like Moser consulting. It is a challenging environment and without proper guidance the waters can be treacherous. Having a mentor with the experience, expertise and longevity as Moser consulting is fantastic.",
        quoteByline: "- Keith Christopher, President Bainbridge Meyers, LLC",
      },
    ],
  },
];

export const careers = [];
