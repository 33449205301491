import { FunctionComponent } from "react";
import { ServiceProps, ServiceType } from "./ServiceTypes";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

export const Service: FunctionComponent<ServiceProps> = ({ service }) => {
  const url = service.name.toLowerCase().split(" ").join("-");
  const navigate = useNavigate();
  const handleNavigation = (service: ServiceType) => {
    navigate(`/services/details/${url}`, { state: service });
  };
  return (
    <div className={styles.box}>
      <div>
        <h2 className={styles.title}>{service.name}</h2>
        <p className={styles.description}>{service.description}</p>
      </div>
      <div className={styles.learnMoreContainer}>
        <span
          className={styles.learnMoreText}
          onClick={() => handleNavigation(service)}
        >
          Learn More about {service.name}{" "}
          <i className="material-icons">arrow_forward</i>
        </span>
      </div>
    </div>
  );
};
