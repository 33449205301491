import { FunctionComponent } from "react";
import { Button } from "components";
import { FeatureSVG } from "./FeatureSVG";
import styles from "./index.module.scss";
import redHat from "./redhat-logo-min.png";
import mdIcon from "./emarylandicon-min.jpg";
import sdvosb from "./sdvosb-min.png";
import vsbe from "./VSBE_Seal.png";
import vip from "./VIP-min.jpg";
import customer_service from "./customer_service.svg";
import cloud from "./cloud.svg";
import device from "./device.svg";

export const Home: FunctionComponent = () => {
  return (
    <>
      <div className={styles.hero}>
        <div className={styles.heroOverlay}></div>
        <FeatureSVG />
        <div className={styles.heroText}>
          <h1 className={styles.heroHeadingOne}>
            Providing solutions that are designed to address your unique IT
            challenges.
          </h1>
          <h3 className={styles.heroHeadingThree}>
            We proudly serve Federal, state, and local government agencies, as
            well as commercial customers. Our more than 25 years of expertise
            gives us the ability to tailor specific IT solutions to help you
            achieve your goals.
          </h3>
          <h3 className={styles.heroHeadingThree}>
            We can and will implement the right solutions tailored to your
            agency or organization's needs.
          </h3>
          <Button to="/Services" text="Learn More" />
        </div>
      </div>
      <div className={styles.highlights}>
        <h3 className={styles.highlightsHeading}>
          A broad range of services, customized to fit your unique needs
        </h3>
        <div className={styles.icons}>
          <div className={styles.iconContainer}>
            <img
              className={`material-icons ${styles.featureIcon}`}
              alt="flaticon cloud icon"
              src={cloud}
            />
            <p className={styles.highlightText}>Innovative Technology</p>
            <p className={styles.highlightSubtext}>
              Stay ahead of the curve with the latest technologies
            </p>
          </div>
          <div className={styles.iconContainer}>
            <img
              className={`material-icons ${styles.featureIcon}`}
              alt="flaticon customer service icon"
              src={customer_service}
            />
            <p className={styles.highlightText}>24/7 Support</p>
            <p className={styles.highlightSubtext}>
              Always available to support your business
            </p>
          </div>
          <div className={styles.iconContainer}>
            <img
              className={`material-icons ${styles.featureIcon}`}
              alt="flaticon devices icon"
              src={device}
            />
            <p className={styles.highlightText}>Device Agnostic</p>
            <p className={styles.highlightSubtext}>
              Regardless of the platform, we have a solution for you
            </p>
          </div>
        </div>
      </div>
      <div className={styles.certified}>
        <div className={styles.imgContainer}>
          <a
            href="https://procurement.maryland.gov/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={mdIcon} alt="E-Maryland Marketplace Logo" width={200} />
          </a>
        </div>
        <div className={styles.imgContainer}>
          <a
            href="https://www.sba.gov/federal-contracting/contracting-assistance-programs/service-disabled-veteran-owned-small-businesses-program"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={sdvosb}
              alt="Service Disabled Veteran Owned Small Business Logo"
              width={100}
            />
          </a>
        </div>
        <div className={styles.imgContainer}>
          <a
            href="https://gomdsmallbiz.maryland.gov/Pages/default.aspx"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={vsbe}
              alt="Veteran-Owned Small Business Enterprise Program Seal"
              width={100}
            />
          </a>
        </div>
        <div className={styles.imgContainer}>
          <a
            href="https://www.redhat.com/en"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={redHat} alt="RedHat Business Partner Logo" width={200} />
          </a>
        </div>
        <div className={styles.imgContainer}>
          <a
            href="https://www.nationalvip.org/Home/Home"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={vip}
              alt="Veteran Institute for Procurement Logo"
              width={150}
            />
          </a>
        </div>
      </div>
      <div className={styles.chooseUs}>
        <div className={styles.certIconsContainer}></div>
        <div className={styles.chooseUsContainer}>
          <h1 className={styles.chooseUsHeading}> Why Choose Us?</h1>
          <p className={styles.chooseUsDescr}>
            We understand that no two businesses are alike. That’s why our team
            of IT professionals, backed by more than 25 years of expertise, are
            committed to providing best-in-class solutions that also support
            your unique business initiatives.
          </p>
          <p className={styles.chooseUsDescr}>
            Move your business technology forward with Bainbridge Meyers.
            Request a free consult now to learn more about our custom-tailored
            solutions.
          </p>
          <Button to="/Contact" text="Request a Free Consult" />
        </div>
      </div>
    </>
  );
};
