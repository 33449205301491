import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export const Error: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Uh Oh</h1>
      <p className={styles.text}>
        The page you are looking for doesn't exist. Why don't you try checking
        out our{" "}
        <Link className={styles.link} to="/Services">
          services{" "}
        </Link>
        or{" "}
        <Link className={styles.link} to="/About">
          latest company news
        </Link>
        ?
      </p>
    </div>
  );
};
