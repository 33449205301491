import { FunctionComponent } from "react";
import { HeroProps } from "./HeroTypes";
import styles from "./index.module.scss";

export const Hero: FunctionComponent<HeroProps> = ({
  title,
  subtitle,
  img,
  imgAlt,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <img className={styles.heroImg} src={img} alt={imgAlt} />
    </div>
  );
};
