import { FunctionComponent, useEffect } from "react";
import { Hero } from "components";
import styles from "./index.module.scss";

export const Contact: FunctionComponent = () => {
  useEffect(() => {
    if (!document?.getElementById("hubspot-form")?.hasChildNodes()) {
      const script = document.createElement("script");
      script.id = "form-script";
      script.type = "text/javascript";
      const app = document.getElementById("App");
      app?.appendChild(script);
      const hbsptForm = window.hbspt.forms.create({
        portalId: "6328788",
        formId: "7a2d9588-7633-45a0-ad86-68c787dff73e",
        target: "#hubspot-form",
      });
      script.innerHTML = hbsptForm;
    }
  }, []);

  return (
    <div>
      <Hero
        title="How can we help?"
        subtitle="Let's talk about your IT needs. Fill out the form below and we'll be in contact within 24 hours."
      />
      <div className={styles.form}>
        <div id="hubspot-form"></div>
      </div>
    </div>
  );
};
