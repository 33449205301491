import { FunctionComponent } from "react";
import styles from "./index.module.scss";

export const Footer: FunctionComponent = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.text}>
        <a
          href="https://www.linkedin.com/company/bainbridge-meyers"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin social-icon"></i>{" "}
        </a>
      </p>
      <p className={styles.text}>
        Email:{" "}
        <a className={styles.text} href="mailto:info@bbmeyers.com">
          info@bbmeyers.com
        </a>
      </p>
      <p className={styles.text}>
        © {new Date().getFullYear()} by Bainbridge Meyers, LLC
      </p>
    </div>
  );
};
